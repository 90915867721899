import { createSlice } from '@reduxjs/toolkit';
import {
  CoinDTO,
  CoinNetworkDTO,
  LocalizationDTO,
  SelectOptionSchema,
} from 'views/types';

type NetworkByCoinSchema = {
  [key: string]: CoinNetworkDTO[];
};

type LocalizationSchema = {
  [key: string]: LocalizationDTO;
};

type SelectNetworksListByCoinSchema = {
  [key: string]: SelectOptionSchema[];
};

interface GlobalInitialState {
  currencyTo: string;
  amountTo: string;
  token: string;
  currencyFrom: string;
  amountFrom: string;
  paymentIntentId: string;
  merchantId: string;
  selectCoinsList: SelectOptionSchema[];
  networksByCoin: NetworkByCoinSchema;
  selectNetworksListByCoin: SelectNetworksListByCoinSchema;
  localization: LocalizationSchema;
  currentLanguage: string;
  pageMode: boolean;
}

const initialState: GlobalInitialState = {
  currencyTo: '',
  amountTo: '',
  token: '',
  currencyFrom: '',
  amountFrom: '',
  paymentIntentId: '',
  merchantId: '',
  selectCoinsList: [],
  networksByCoin: {},
  localization: {},
  selectNetworksListByCoin: {},
  currentLanguage: '',
  pageMode: false,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGlobalParams(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setCoinsData(state, action) {
      const selectCoinsList: SelectOptionSchema[] = [];
      const networksByCoin: NetworkByCoinSchema = {};
      const selectNetworksListByCoin: SelectNetworksListByCoinSchema = {};
      action.payload.forEach((item: CoinDTO) => {
        const selectNetworksList = item.coinNetworks.map((network) => ({
          label: network.networkName,
          extraData: {
            addressType: network.addressType,
            isTagRequiredForWithdrawals: network.isTagRequiredForWithdrawals,
          },
          value: network.networkCode,
        }));
        selectCoinsList.push({
          label: item.coin,
          value: item.coin,
          extraData: {
            name: item.name,
          },
        });
        networksByCoin[item.coin] = item.coinNetworks;
        selectNetworksListByCoin[item.coin] = selectNetworksList;
      });
      return {
        ...state,
        selectCoinsList,
        networksByCoin,
        selectNetworksListByCoin,
      };
    },
    setLocalization(state, action) {
      const { languageIso2, resources } = action.payload;
      return {
        ...state,
        localization: {
          ...state.localization,
          [languageIso2]: resources,
        },
      };
    },

  },
});

export const {
  setGlobalParams,
  setCoinsData,
  setLocalization,
} = globalSlice.actions;
export default globalSlice.reducer;
