import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RefundDTO, RefundFeeDTO, RefundFeePayloadSchema } from 'views/types';

const baseUrl = '/api/v1/';
// const baseUrl = 'https://payment.cryptopay-dev.space-app.io/api/v1/';
const tags = ['Refund'];

export const refundApi = createApi({
  reducerPath: 'refundApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  tagTypes: tags,
  endpoints: (builder) => ({
    postRefundFee: builder.mutation<RefundFeeDTO,
      {refundFeeData: RefundFeePayloadSchema} & {authToken: string}
    >({
      invalidatesTags: tags,
      query: ({ refundFeeData, authToken }) => ({
        url: 'payment-intents/crypto-refunds/fee',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: refundFeeData,
      }),
    }),
    postRefund: builder.mutation<RefundDTO,
      {authToken: string}>({
        invalidatesTags: tags,
        query: ({ authToken }) => ({
          url: 'payment-intents/crypto-refunds',
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }),
      }),
  }),
});

export const {
  usePostRefundFeeMutation,
  usePostRefundMutation,
} = refundApi;
