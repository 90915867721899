export type LocalizeNumberOptions = {
  minDigits?: number;
  maxDigits?: number;
  rounding?: 'up' | 'down' | 'trunc';
};

const CRYPTO_MAX_FRACTION = 8 as const;

export const localizeNumber = (
  number: number | string | undefined | null,
  options?: LocalizeNumberOptions,
) => {
  const minimumFractionDigits = options?.minDigits === undefined || options?.minDigits < 0
    ? undefined
    : options?.minDigits;
  const maximumFractionDigits = options?.maxDigits ?? minimumFractionDigits ?? CRYPTO_MAX_FRACTION;

  let targetNumber = Number(number) || 0;
  const precision = targetNumber.toString().split('.')[1] ?? '';

  if (precision.length > maximumFractionDigits) {
    // Rounding behavior
    if (options?.rounding === 'up') {
      targetNumber = Math.ceil(targetNumber * 10 ** maximumFractionDigits)
        / 10 ** maximumFractionDigits;
    } else if (options?.rounding === 'down') {
      targetNumber = Math.floor(targetNumber * 10 ** maximumFractionDigits)
        / 10 ** maximumFractionDigits;
    } else {
      // eslint-disable-next-line max-len
      // by default `toLocaleString` applies the math rounding, to avoid it we just cut our number by digits.
      // E.g. number: 0.777777, digits: 3 => [0.777, instead of 0.778]
      targetNumber = Math.trunc(targetNumber * 10 ** maximumFractionDigits)
        / 10 ** maximumFractionDigits;
    }
  }

  return Math.abs(targetNumber).toLocaleString('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
  });
};
